import { render, staticRenderFns } from "./ChannelDetailedInformation.vue?vue&type=template&id=590c1b05&scoped=true&"
import script from "./ChannelDetailedInformation.vue?vue&type=script&lang=ts&"
export * from "./ChannelDetailedInformation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590c1b05",
  null
  
)

export default component.exports