

















import ChannelDetailedInformation from '@/components/ChannelDetailedInformation.vue'
import { TestChannelStateCode } from '@/includes/types/Board.types'
import ChannelSettings from '@/components/ChannelSettings.vue'
import ChannelLicenseInformation from '@/components/ChannelLicenseInformation.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import moment from 'moment'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    SaveChannelConfig,
    ChannelLicenseInformation,
    ChannelSettings,
    ChannelDetailedInformation,
    PageTitle,
  },
  data() {
    return {
      moment
    }
  }
})
export default class ChannelMainSettings extends Vue {
  updateChannel(payload: { state: TestChannelStateCode, channelId: number, boardId: string }):void {
    this.$store.dispatch('getChannelConfig', { board_key:payload.boardId, channel_id:payload.channelId })
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
