


















































































import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import moment from 'moment'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    AListCustom,
    AListCustomItem,
    HighlightAnchor
  },
  data() {
    return {
      moment
    }
  }
})
export default class ChannelLicenseInformation extends Vue {
  buyConfig = {
    name: 'Buy_channel', params: {
      [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
      [EntityTypes.CHANNEL_ID]: this.$route.params[EntityTypes.CHANNEL_ID]
    }
  }
  
  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  get channelLicenseBuyButtonTitle() {
    if (this.channel) {
      if (!this.channel.licence_expired) {
        return this.$t('button_license_prolongate').toString()
      } else {
        return this.$t('button_license_buy').toString()
      }
    }
  }

  get channelLicenseStatus() {
    if (!this.channel.licence_expired) {
      return this.$t('license_active').toString()
    } else if (this.$store.getters.channelLicenseExpires) {
      return this.$t('license_expires').toString()
    } else {
      return this.$t('license_expired').toString()
    }
  }

  get licenseStatusColor() {
    if (!this.channel.licence_expired) {
      return 'text-success'
    } else if (this.$store.getters.channelLicenseExpires) {
      return 'text-warning'
    } else {
      return 'text-danger'
    }
  }

  get freeLicenseTypeTitle() {
    if (this.channel) {
      if (this.channel.free_licence) {
        if (!this.channel.licence_expired && this.channel.members_count < 1000) {
          return this.$t('free_license_under_1000').toString()
        }

        return this.$t('free_license_test_period').toString()
      } else {
        return ''
      }
    }
  }
}
