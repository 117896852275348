














































import SlotsList from '@/components/Slots/SlotsList.vue'
import { successNotification } from '@/includes/services/NotificationService'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    SlotsList,
    EmptyData,
    ConfigField,
    HighlightAnchor
  }
})
export default class Slots extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {

  resetSlots():void {
    this.$confirm({
      title: this.$t('reset_slots_warning').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        this.$store.commit('reset_slots')
        this.$store.dispatch('save_active_channel_config', this.$route)
      },
    })
  }

  copySlots():void {
    this.$store.dispatch('set_copied_slots', this.$store.state.channelsState.activeChannel?.config.slots)
      .then(() => successNotification(this.$t('slots_copied').toString()))
  }

  pasteSlots():void {
    const localSlotsBackup = cloneDeep(this.$store.state.channelsState.activeChannel?.config.slots)

    this.$store.dispatch('set_slots_to_config', this.$store.state.channelsState.copiedSlots)

      .then(() => {
        this.$confirm({
          title: this.$t('save_channel_settings_title').toString(),
          onOk: () => {
            this.$store.dispatch('save_active_channel_config', this.$route)
          },
          onCancel: () => {
            this.$store.dispatch('set_slots_to_config', localSlotsBackup)
          },
          okText: this.$t('accept').toString(),
          cancelText: this.$t('reject').toString()
        })
      })
  }

  saveChannelConfig() {
    this.$store.dispatch('save_active_channel_config', this.$route)
  }

  addSlot(slot:any) {
    this.$store.commit('add_slot_to_channel', slot)
    this.saveChannelConfig()
  }

  updateSlot(slot:any) {
    this.$store.commit('update_slot', { itemIndex: slot.index, slot })
    this.saveChannelConfig()
  }

  removeSlot(slotIndex:number) {
    this.$store.commit('remove_slot', slotIndex)
    this.saveChannelConfig()
  }
}
